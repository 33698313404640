<template>
  <div>
      <v-container>
        <div >
          <v-alert
            border="left"
            class="border"
            colored-border
            color="#00919B"
            icon="mdi-information-outline"
            elevation="0" >
          <p >You have access to <b> {{ arrays.companies ? arrays.companies.length : '0' }}</b> company. Are you a company owner?</p>
          <v-btn text class="bg-white" color="#0A009B" @click="register">
            <v-icon class="mr-5" size="20"> mdi-domain-plus </v-icon>
            create my own company</v-btn>
          </v-alert>
          <v-row>
            <v-col cols="12" lg="9" md="9" sm="12" >
              <h4 class="mb-5">Your Products</h4>
              <v-row justify="center" v-if="!arrays.products">
                <v-progress-circular
                  :size="100"
                  indeterminate
                  class="text-center px-12"
                ></v-progress-circular>
              </v-row>
              <div v-else-if="arrays.products.length > 0" v-for="yourproduct in arrays.products"  :key="yourproduct.id" >
                <v-card outlined class="mb-3" >
                  <div class="p-3">
                    <v-row align="center">
                      <v-col cols="12" lg="3" md="4" sm="12" class="">
                        <div class="d-flex align-center justify-content-center p-2">
                          <v-img src="@/assets/default.svg" max-height="150" contain > </v-img>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="9" md="8" sm="12" >
                        <p class="h4 text-dark">{{ yourproduct.products.name }}</p>
                        <p class="black--text mt-1 mb-0">{{ yourproduct.products.description | productDescriptionMaxString }}</p>
                        <div class="mt-2 hidden-sm-and-down ">
                          <v-tooltip top class="border" >
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip  class="bg-white" v-bind="attrs" v-on="on" >
                                <v-icon left color="#00919B" size="20"> mdi-alert-circle </v-icon>
                                  <small v-if="yourproduct.status == 'paid' && !yourproduct.expired "> Subscribe </small>
                                  <small v-if="yourproduct.status == 'trial' "> Trial </small>
                                  <small v-if="yourproduct.expired"> Expired </small>
                              </v-chip>
                            </template>
                            <span>Subscription Status</span>
                          </v-tooltip>
                          <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip  class="bg-white" v-bind="attrs" v-on="on" >
                                <v-icon left color="#00919B" size="20"> mdi-clock-start </v-icon>
                                  <small>  {{ yourproduct.start_of_subscription | displayDatev2 }}  </small>
                              </v-chip>
                            </template>
                            <span>Start of Subscription</span>
                          </v-tooltip>
                          <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip  class="bg-white" v-bind="attrs" v-on="on" >
                                <v-icon left color="#00919B" size="20"> mdi-clock-end </v-icon>
                                  <small>  {{ yourproduct.end_of_subscription | displayDatev2 }}  </small>
                              </v-chip>
                            </template>
                            <span>End of Subscription</span>
                          </v-tooltip>
                        </div>
                         <div class="mt-2 hidden-md-and-up ">
                              <p  class="bg-white  black--text mb-2"  >
                                <small class="text-secondary"> Subscription Status  </small> <br>
                                  <span v-if="yourproduct.status == 'paid' && !yourproduct.expired "> Subscribe </span>
                                  <span v-if="yourproduct.status == 'trial' "> Trial </span>
                                  <span v-if="yourproduct.expired"> Expired </span>
                              </p>
                              <p  class="bg-white   black--text mb-2" >
                                <small class="text-secondary"> Start of Subscription </small> <br>
                                  <span>  {{ yourproduct.start_of_subscription | displayDatev2 }}  </span>
                              </p>
                              <p  class="bg-white  mb-0 black--text"  >
                                <small class="text-secondary"> End of Subscription </small> <br>
                                  <span>  {{ yourproduct.end_of_subscription | displayDatev2 }}  </span>
                              </p>
                
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  
                  <v-card-actions class="border-top">
                    <div class="w-100 text-right ">
                      <v-btn text class="mr-2 text-secondary" @click="gotoProduct(yourproduct.products.id)">DETAILS</v-btn>
                      <v-btn color="#0A009B" text  elevation="0" @click="redirect(yourproduct.products.link)">GO TO PRODUCT</v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </div>
              
              <div class="py-6" v-else>
                <h3 class="text-center text-secondary">No Subscriptions for now</h3>
              </div>
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="12" >
              <h4 class="mb-5">Summary</h4>
              <v-card outlined class="mb-3">
                <div class="w-100 text-center p-4" v-if="!arrays.wallet">
                  <v-progress-circular
                    :size="100"
                    indeterminate
                    absolute="true"
                    class="text-center px-12" >
                  </v-progress-circular>
                </div>
                <div v-else>
                  
                  <v-card-text class="">
                    <div class="d-flex align-center">
                      <div class="ma-2 rounded-circle border p-2"  >
                        <v-btn icon @click="goto('Earning')" >
                          <v-icon color="#00919B">mdi-wallet-outline</v-icon>
                        </v-btn>
                      </div>
                      
                      <div class="w-100 text-center ">
                       
                        <router-link class="h3 mb-0 text-dark text-center d-block" to="/account/affiliate/earnings">
                          <span  v-if="arrays.wallet.balance">{{ arrays.wallet.balance | abbrivation  }} </span>
                            <span v-else>0</span>
                          </router-link>
                        <small class="text-secondary mt-0">Wallet balance </small>
                      </div>
                    </div>
                  </v-card-text>
                </div>
                
              </v-card>

              <v-card outlined class="mb-3" >
                <div class="w-100 text-center p-4" v-if="!arrays.invites">
                  <v-progress-circular  
                    :size="100" 
                    indeterminate
                    absolute="true"
                    class="text-center px-12" >
                  </v-progress-circular>
                </div>
                <div v-else>
        
                  <v-card-text >
                    <div class="d-flex align-center">
                      <div class="ma-2 rounded-circle border p-2"  >
                        <v-btn icon @click="goto('Earning')">
                          <v-icon color="#00919B">mdi-account-outline</v-icon>
                        </v-btn>
                      </div>
                      <div class="w-100 text-center">
                        <router-link to="/account/affiliate/earnings"  class="h3 mb-0 text-dark d-block text-center">{{ arrays.invites.total | formatNumber }}</router-link>
                        <small class="text-secondary mt-0">Registered invitations</small>
                      </div>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
              <v-card outlined v-if="status.admin">
                <div class="w-100 text-center p-4" v-if="!arrays.users">
                  <v-progress-circular
                    :size="100"
                    indeterminate
                    absolute="true"
                    class="text-center px-12" >
                  </v-progress-circular>
                </div>
                <div v-else>
                  <v-card-text >
                    <div class="d-flex align-center">
                      <div class="ma-2 border rounded-circle p-3" >
                        <v-icon  color="#00919B">mdi-account-multiple-outline</v-icon>
                      </div>
                      <div class="w-100 text-center">
                        <p class="h3 mb-0 text-dark ">{{ arrays.users.total | formatNumber}}</p>
                        <small class="text-secondary mt-0">Total employees</small>
                      </div>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>

    <Footer></Footer>
  </div>
</template>

<script>
export default {
  props: {
    myInfo: Object
  },
  data: () => ({
    loading: false,
    common : {
      user_company_id : null,
    },
    status : {
      admin : false
    },
    arrays : {
      me : null,
      products : null,
      wallet : null,
      invites : null,
      users: null,
      companies : null
    },
  }),
  created() {
    if(this.myInfo)
    {
      this.getInit()
    }
  },
  watch : {
    myInfo : function(val)
    {
      if(val)
      { 
        this.getInit()
      }
    }
  },
  methods: {
    goto(page)
    {
      this.$router.push({name : page})
    },
    register()
    {
      this.$router.push({path: '/account/mycompanies'})
    },
    getInit()
    {
      this.arrays.me = this.myInfo
      if(this.arrays.me.current_company)
      {
        this.status.admin = true
      }
      this.common.user_company_id = this.arrays.me.default_user_company.company.id

      this.getUsersCompanies()
    },
    redirect(url) {
      window.open(url)
    },
    
    Dashboard() {
      this.loading = true;
      let user_id = this.arrays.me.default_user_company.id
      this.axios
        .get("subscriptions?company_id=" +this.common.user_company_id+"&latest_expiration=true"+"&user_company_id="+user_id)
        .then(({ data }) => {
          this.arrays.products = this.reconstructProductLink(data.subscription);
        })
        .catch()
        .finally(() => {
          this.loading = false;
          this.getWallet()
        });
    },
    getUsersCompanies(){
      let url = 'getusercombyuserid/'+this.arrays.me.id
      this.axios.get(url)
      .then( ({data}) => {
        this.arrays.companies = data.companies
        
      })
      .finally( () => {
          this.Dashboard()
      })

    },
    reconstructProductLink(data)
    {
      if(data.length > 0)
      {
        for( let i in data)
        {
          if(data[i].products && data[i].products.name)
          {

            let name =  data[i].products.name.toLowerCase()
           
            data[i].products.link = this.$options.filters.productLink(name, data[i].products.url, this.arrays.me.default_user_company.id , this.arrays.me.default_user_company.company.id)
            

            if(data[i].end_of_subscription)
            {
              data[i].expired = this.$options.filters.subscriptionChecker(data[i].end_of_subscription)
            }
          }
        }
      }
      return data
    },
    getWallet() {
      this.axios
        .get("accounting?wallet=true&wallet_balance=true&per_page=1&user_id=" + this.arrays.me.id)
        .then(({ data }) => {
          if(data.accounting[0])
          {
            let balance = this.checkWalletBalance(data.accounting[0])
            if(balance > 0)
            {
              this.arrays.wallet = data.accounting[0]
              this.arrays.wallet['balance'] = balance
            }else{
              this.arrays.wallet = []
              this.arrays.wallet['balance'] = 0
            }
          }else{
            this.arrays.wallet = []
            this.arrays.wallet.balance = 0
          }
        })
        .catch( () => {
          this.arrays.wallet.balance = 0
        })
        .finally( () => {
          this.getInvite()
          
        })
      },
    checkWalletBalance(wallet)
    {
      let debit = 0
      let credit = 0
      if(wallet.credit_total)
      {
        credit = wallet.credit_total
      }

      if(wallet.debit_total)
      {
        debit = wallet.debit_total
      }
      return debit + credit
    },
    getInvite()
    {
      this.axios.get('inviter?per_page=1&referral_code='+this.arrays.me.referral_code)
      .then( ({data}) => {
          this.arrays.invites = data.inviter
          this.arrays.invites['total'] = data.meta.total
      })
      .catch( () => {
        this.arrays.invites = []
        this.arrays.invites.total = 0
      })
      .finally(() => {
        if(this.status.admin)
        {
          this.getUsers()
        }
      })
    },
    getUsers()
    {
      this.axios
        .get("/userscompanies?per_page=1&company_id=" + this.arrays.me.current_company.id)
        .then(({ data }) => {
          this.arrays.users = data.users_company
          this.arrays.users['total'] = data.meta.total
        });
    },
    gotoProduct(id)
    {
      this.$router.push({path: '/product/'+id } )
    }
  },
};
</script>


<style scoped>
#btn {
  text-transform: none;
  color: #01577a;
}
button:focus, button:active{
  outline: none !important;
}
.welcome-image{
  min-height: 200px;
  max-height: 200px;
  position: relative;
  display: flex;
}
.icon-container{
  min-height: 50px;
  max-height: 50px;
}
.p-b-r{
  position: absolute;
  right: 5px;
  bottom: 10px;
}
</style>